module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kuda. - The money App for Africans.","short_name":"Kuda.","start_url":"/","background_color":"#40196d","theme_color":"#40196d","display":"minimal-ui","icon":"./static/favicon/kuda-app-icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"817086b560dfc1021d60b8ab9b66d485"},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"Ws4QQixp3PWqBe8rP8nI5lMotiIbo6Yq","devKey":"tSofb04EhLeJG6LGX8C4EHueaqIg0uCt"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
